export function swipeBack(node, { onClose }) {
    let evtStartX = 0;
    let evtMoveX = 0;
    // let enabled = window.location.search.includes('swipe=true');
    let enabled = true;

    /**
     * @param {TouchEvent} evt
     */
    function ontouchstart(evt) {
        const evtTarget = evt.targetTouches.item(0);
        evtStartX = evtTarget.clientX;
    }

    function ontouchend() {
        if (evtMoveX > 200) {
            node.style.setProperty('--tw-translate-x', '100%');
            setTimeout(() => {
                onClose && onClose();
                node.style.setProperty('--tw-translate-x', null);
            }, 150);
        } else {
            node.style.setProperty('--tw-translate-x', null);
        }
        evtStartX = 0;
        evtMoveX = 0;
    }

    /**
     * @param {TouchEvent} evt
     */
    function ontouchmove(evt) {
        const evtTarget = evt.targetTouches.item(0);
        if (evtStartX > 0 && evtStartX < evtTarget.clientX) {
            const diff = evtTarget.clientX - evtStartX;
            // console.log('Move:', diff)
            if (diff > 0) {
                node.style.setProperty('--tw-translate-x', `${diff}px`);
                evtMoveX = diff;
            }
        } else {
            evtMoveX = 0;
        }
    }

    if (enabled) {
        node.addEventListener('touchstart', ontouchstart);
        node.addEventListener('touchmove', ontouchmove);
        node.addEventListener('touchend', ontouchend);
    }

    return {
        destroy() {
            if (enabled) {
                node.removeEventListener('touchstart', ontouchstart);
                node.removeEventListener('touchmove', ontouchmove);
                node.removeEventListener('touchend', ontouchend);
            }
        }
    };
}
