// const isDesktop = window.innerWidth > 620

export function setupVideoSrc(video) {
    let sources = video.querySelectorAll('source');
    for (let j = 0; j < sources.length; j++) {
        // console.log('setupVideo', sources[j], sources[j].dataset)
        if (sources[j].dataset.src) {
            sources[j].src = sources[j].dataset.src;
        }
    }
}

/**
 *
 * @param {string} tokenID
 */
export function getThumbnail(tokenID) {
    return `https://nfts.odlabs.io/lulus/thumbnail-320/${tokenID}@320.webp`;
}

export function getThumbnailEpoch(epochID) {
    return `https://nfts.odlabs.io/lulus/thumbnail-epoch-320/${epochID}@320.webp`;
}

/**
 *
 * @param {string} tokenID
 */
export function getImageFull(tokenID) {
    return `https://nfts.odlabs.io/lulus/thumbnail-1024/${tokenID}@1024.webp`;
}

const blobCache = {};

/**
 * We load a media source using blob, then capture the blob result into local cache
 * Next time the same resource is requested, we load it from the cache.
 * Once the blob is loaded, we add the blob as the src of the nodeRef
 *
 * @param {HTMLMediaElement} nodeRef
 * @param {String} src
 */
export async function loadMediaBlob(nodeRef, src, hideOnLoad = true) {
    if (!nodeRef) {
        console.error('Invalid media node for:', src);
        return false;
    }

    if (blobCache[src]) {
        nodeRef.setAttribute('src', blobCache[src]);
        nodeRef.style.visibility = null;
        return true;
    }

    // keep space but hidden while src is loaded...
    hideOnLoad && (nodeRef.style.visibility = 'hidden');

    try {
        const isVideo = src.includes('.webm') || src.includes('.mov');
        isVideo && nodeRef.setAttribute('src', src); // Use direct source to allow video be streamed

        const res = await fetch(src);
        const blob = await res.blob();
        const blobURL = URL.createObjectURL(blob);
        if (!isVideo && nodeRef) {
            nodeRef.setAttribute('src', blobURL);
        }
        blobCache[src] = blobURL;
        nodeRef && (nodeRef.style.visibility = null);
    } catch (ex) {
        // do nothing and ignore the error to keep the image invisible?
    }
    return false;
}
