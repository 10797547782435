<script>
    import { onMount } from "svelte";
    import { get } from "../../utils/Backend";
    import { getThumbnail, getImageFull, loadMediaBlob } from "../../utils/MediaUtils";
    import { unrevealedImage } from "../../utils/Store";
    import { lazy } from "../../utils/Lazy";

    /** @type {import("../../utils/Store").FamilyToken} */
    export let parent

    export let onClose

    let itemsList = []

    let currentItem = {...parent}
    let currentImgRef

    let isLoadingItem = false
    let retriesTimeout


    let rarityRanks = [{
        color: "#998CA6",
        percentile: 100,
        type: "Common"
    }, {
        color: "#00965C",
        percentile: 60,
        type: "Uncommon"
    }, {
        color: "#4A01E4",
        textColor: "#A480F1",
        percentile: 35,
        type: "Rare"
    }, {
        color: "#8D23F6",
        textColor: "#BB7BFA",
        percentile: 15,
        type: "Epic"
    }, {
        color: "#DC9300",
        percentile: 5,
        type: "Legendary"
    }, {
        color: "#E0005D",
        percentile: 1,
        type: "Mythic"
    }];

    onMount(() => {
        const loadChildren = async () => {
            const endpoint = `/api/nfts/family/parents/${parent.Epoch}/children`
            get(endpoint).then(children => {
                itemsList = [...[parent], ...children]
            }).catch(ex => {
                console.error('error??', ex.message)
                retriesTimeout = setTimeout(loadChildren, 10000)
            })
        }

        if (parent && currentImgRef) {
            loadChildren()

            if (parent.Revealed) {
                loadRarity(parent.TokenID)

                // fast load of mini thumbnail
                loadMediaBlob(currentImgRef, currentImgRef.dataset.src, false).then(() => {
                    // Load HQ image after render the LQ image
                    loadMediaBlob(currentImgRef, getImageFull(parent.TokenID.toString()), false)
                })
            }
        }

        return () => {
            if (retriesTimeout) {
                clearTimeout(retriesTimeout)
            }
        }
    })
    

    /**
     * 
     * @param {import("../../utils/Store").FamilyToken} thumb
     */
    async function selectThumbnail(thumb) {
        if (isLoadingItem) {
            return false
        }

        
        currentItem = thumb
        loadRarity(thumb.TokenID)
        
        
        isLoadingItem = true
        // fast load of mini thumbnail
        loadMediaBlob(currentImgRef, getThumbnail(thumb.TokenID.toString()), false).then(() => {
            // then load HQ image
            loadMediaBlob(currentImgRef, getImageFull(thumb.TokenID.toString()), false).then(() => {
                isLoadingItem = false
            })
        })
    }

    const storeBase = 'https://magiceden.io/item-details/ethereum/0x8cfa6f29930e6310b6074bab0052c14a709b4741'
    function getStoreLink(tokenID) {
        return `${storeBase}/${tokenID}`
    }

    const rarityCache = {}
    /**
     * Load Token Rarity from MagicEden?
     * @param tokenID
     */
    async function loadRarity(tokenID) {
        try {
            const cacheData = rarityCache[tokenID]
            if (cacheData) {
                currentItem.rarity = cacheData.rarity
                currentItem.rank = cacheData.rank
                return
            }

            const tokensCountObj = await get('/api/nfts/tokens/revealed/count')
            // console.log('Revealed Count:', count)

            const endpoint = `https://api-mainnet.magiceden.io/v3/rtp/ethereum/tokens/v7?tokens=0x8cfa6f29930e6310b6074bab0052c14a709b4741%3A${tokenID}&limit=1&excludeSpam=true&includeTopBid=false&includeAttributes=false&includeQuantity=false&includeLastSale=false&normalizeRoyalties=false`
            const data = await get(endpoint)
            if (data && data.tokens && data.tokens.length>0) {
                const tokenData = data.tokens[0].token
                // currentItem.rarity = (tokenData.rarity / 100).toFixed(2)
                currentItem.rank = tokenData.rarityRank
                
                const rankValue = 100 * Math.min(1, tokenData.rarityRank / tokensCountObj.count);
                // console.log('rarityIndex:', rankIndex)
                const rankIndex = rarityRanks.findLastIndex(rank => rank.percentile>=rankValue)

                currentItem.rarity = rarityRanks[rankIndex]
                
                rarityCache[tokenID] = {
                    rarity: rarityRanks[rankIndex],
                    rank: currentItem.rank
                }
            }
        } catch(ex) {
            console.error('API Error:', ex.message)
        }
    }
</script>
<div class="family-gallery w-full h-full flex flex-col relative">
    <header class="flex mb-5">
        <button class="py-1 px-2 absolute top-3 -left-2" on:click={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 inline">
                <path fill-rule="evenodd" d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd" />
            </svg><span class="inline text-transparent">. .</span>
        </button>

        <h2 class="text-center flex-1 text-4xl mt-3 font-rademos">{parent.Type} {parent.ParentName}</h2>
    </header>

    
    <div class="w-full mb-4 flex justify-center relative z-10">

        {#if currentItem.Revealed}
            <img src={$unrevealedImage} data-src={getThumbnail(currentItem.TokenID.toString())} alt="lulu-loading" class="w-full aspect-square" bind:this={currentImgRef} style={'visibility: hidden;'} />
            
            <a href={getStoreLink(currentItem.TokenID)} class="absolute top-4 left-4 w-9" target="_blank">
                <img src="/images/icon-store-me.svg" alt="Buy">
            </a>

            {#if currentItem.rarity}
                <div class="info absolute bottom-4 left-4 font-impact">
                    <p>Rarity: {currentItem.rarity.percentile}%</p>
                    <p>Rank: #{currentItem.rank}</p>
                </div>
            {/if}
        {:else}
            <img src={$unrevealedImage} alt="unrevealed" class="w-full aspect-square" bind:this={currentImgRef} />
        {/if}


    </div>

    <div class="flex-1 overflow-scroll">
        <div class="grid grid-cols-3 gap-2">
            {#if itemsList.length > 0}
                {#each itemsList as item}
                    {#if item.Revealed}
                    <button
                        class="card"
                        on:click={(evt) => { evt.preventDefault(); selectThumbnail(item) }}
                        >
                        <img use:lazy data-src={getThumbnail(item.TokenID)} alt={item.TokenID} data-token={item.TokenID}>
                        {#if item.TokenID===currentItem.TokenID}
                        <div class="active absolute w-full h-full pointer-events-none" class:activeLoading={isLoadingItem}></div>
                        {/if}
                    </button>
                    {:else}
                    <img src={$unrevealedImage} alt="unrevealed" class="w-full aspect-square" >
                    {/if}
                {/each}
            {:else}
                {#each Array(6) as _, index (index)}
                    <div class="card animate-pulse aspect-square"></div>
                {/each}
            {/if}
        </div>
    </div>
</div>


<style>
    .card {
        background-size: cover;
        background-repeat: no-repeat;
        @apply relative rounded-lg bg-white/20 w-full aspect-square flex flex-col overflow-hidden;
    }

    .card .active {
        box-shadow: inset 0px 0px 10px rgba(250, 250, 250, 0.9);
        border: 1px solid rgba(250, 250, 250, 0.5);
    }

    .active.activeLoading {
        box-shadow: inset 0px 0px 10px rgba(20, 20, 20, 0.9);
        border: 1px solid rgba(20, 20, 20, 0.5);
    }

    .info p {
        text-shadow: #000 1px 0 5px;
    }
</style>