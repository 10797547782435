<script>
    import { onMount, afterUpdate } from 'svelte';
    import WalletConnect from '../components/WalletConnect.svelte';
    import { isAlreadyInstalled, isAppInstalled, pageTitle } from '../utils/Store';
    import {
        isWalletConnected,
        loadWalletData,
        userNFTs,
        walletIconStyles
    } from '../utils/Store-Wallet';
    import { wrapGrid } from 'animate-css-grid';
    import { getImageFull, getThumbnail, loadMediaBlob } from '../utils/MediaUtils';
    import { lazy } from '../utils/Lazy';
    import { connectWallet } from '../utils/Wallet/Connect';
    import { getIframeURL } from '../utils/Backend';

    // Iframe URL to use based on the current app location
    const webarBaseURL = getIframeURL();

    /** @type {'marker' | 'slam'} */
    let iframeAppMode = 'marker'; //

    let iframeSrc;
    let iframeRef;

    /** @type {HTMLElement} */
    let gridRef;
    let isGridMounted = false;

    let selectedToken = 0;

    // Interval to refresh NFT list
    let intervalWallet;

    /** @type Array<import("../utils/Wallet/NFTs").NFT> */
    let nfts = [];

    /** @type {import("../utils/Wallet/NFTs").NFT} */
    let currentNFT = null;

    onMount(() => {
        pageTitle.set('GALLERY');

        const onWalletSubscription = (isConnected) => {
            if (isConnected) {
                loadWalletData();
                intervalWallet = setInterval(loadWalletData, 60000); // load data each 60 seconds in case the user leave this window open
            }
        };
        const unsubWallet = isWalletConnected.subscribe(onWalletSubscription);

        const unsubNFT = userNFTs.subscribe((val) => {
            // console.log('NFTS list...', val);
            nfts = [...val];
        });

        return () => {
            unsubNFT();
            unsubWallet();
            if (intervalWallet) {
                clearInterval(intervalWallet);
            }
        };
    });

    afterUpdate(() => {
        if (gridRef && !isGridMounted) {
            // Animate Grid
            wrapGrid(gridRef, { duration: 200, easing: 'linear' });
            isGridMounted = true;
        }
    });

    /**
     *
     * @param {import("../utils/Wallet/NFTs").NFT} nft
     */
    function openNFT(nft, e) {
        // console.log('Open NFT:', nft.token_id)
        if (currentNFT && currentNFT.token_id === nft.token_id) {
            currentNFT = null;
        } else {
            currentNFT = nft;

            // wait until the div is added into DOM
            setTimeout(
                (el) => {
                    el &&
                        el.scrollIntoView({
                            block: 'start',
                            behavior: 'smooth'
                        });
                },
                100,
                e.currentTarget
            );

            const img = e.currentTarget.querySelector('img');
            const hqImg = getImageFull(nft.token_id);
            loadMediaBlob(img, hqImg, false);
        }
    }

    function activateAR(token_id, evt) {
        evt.preventDefault();
        evt.stopPropagation();

        const setIframeToken = () => {
            selectedToken = token_id;
            iframeSrc =
                webarBaseURL
                    .replace('_NOW_', Date.now().toString())
                    .replace('_MODE_', iframeAppMode) + token_id;

            // wait until the div is added into DOM
            // Instead of wait, could we use afterUpdate?
            setTimeout(() => {
                // iframeRef.classList.add('invisible');
            }, 100);
        };
        setIframeToken();

        // Request permission and load iframe on success
        /* navigator.permissions.query({ name: 'camera' }).then((permissionStatus) => {
            if (permissionStatus.state === 'granted') {
                setIframeToken();
            } else {
                console.warn('Permissions not granted yet!');
                navigator.mediaDevices
                    .getUserMedia({ video: { facingMode: 'environment' } })
                    .then(setIframeToken)
                    .catch((ex) => {
                        closeAR();
                        alert('Camera Permissions denied!');
                    });
            }
        }); */
    }

    function closeAR() {
        selectedToken = 0;
    }

    function onFrameload() {
        iframeRef.classList.remove('invisible');
    }
</script>

<div
    class="page-gallery w-full h-full flex flex-col items-center touch-none overflow-hidden"
    class:justify-center={!$isWalletConnected}
>
    {#if $isWalletConnected}
        <header class="flex items-center w-full py-4 text-center z-10 relative">
            <button class="absolute top-4 left-6 z-20 min-w-7 min-h-7" on:click={connectWallet}>
                <span class="wallet-icon" style={$walletIconStyles}></span>
            </button>
            <h1 class="font-impact text-white text-2xl mx-auto uppercase">{$pageTitle}</h1>
        </header>

        <div class="page overflow-scroll w-full px-6">
            <!-- Gallery Grid -->
            <div bind:this={gridRef} class="grid gap-4 grid-cols-2 grid-flow-dense pb-6">
                {#each nfts as nft}
                    <div
                        class="card"
                        class:col-span-2={currentNFT && nft.token_id === currentNFT.token_id}
                    >
                        <button
                            on:click={(e) => {
                                openNFT(nft, e);
                            }}
                        >
                            <img
                                use:lazy
                                data-src={getThumbnail(nft.token_id)}
                                alt={`token-${nft.token_id}`}
                                class="w-full aspect-square touch-none pointer-events-none"
                            />

                            <div class="card-footer">
                                LULU #{nft.token_id.padStart(4, '0')}

                                {#if currentNFT && nft.token_id === currentNFT.token_id}
                                    <button
                                        class="btnAR"
                                        on:click={(e) => {
                                            activateAR(nft.token_id, e);
                                        }}>Activate AR</button
                                    >
                                {/if}
                            </div>
                        </button>
                    </div>
                {/each}
            </div>

            <!-- iframe for selected item -->
            <div
                class="fixed page-iframe animate-translate z-20 top-0 left-0 w-full h-full bg-black"
                class:translate-y-full={!selectedToken}
                class:translate-y-0={selectedToken > 0}
            >
                {#if selectedToken > 0}
                    <button
                        class="close absolute top-4 right-4 w-9 text-4xl z-[99]"
                        on:click={closeAR}
                    >
                        &times;
                    </button>

                    <iframe
                        src={iframeSrc}
                        bind:this={iframeRef}
                        frameborder="0"
                        title="AR Experience"
                        allow="camera;microphone;fullscreen;gyroscope;accelerometer;magnetometer;xr;vr;autoplay;"
                        class="relative w-full h-full invisible"
                        on:load={onFrameload}
                    ></iframe>

                {/if}
            </div>
        </div>
    {:else}
        <!-- Condition when we are running over the installed app -->
        {#if $isAppInstalled}
            <WalletConnect />
        {/if}

        <!-- Condition is the user is in the browser trying to load again the app that is already installed but not opened -->
        {#if $isAlreadyInstalled}
            <div class="modal">
                <h2 class="font-DINRoundPro-Bold text-2xl my-2">ODVZN is already installed</h2>
                <h3 class="font-DINRoundPro-Med text-lg mt-4 mb-4">Open your app to continue</h3>
            </div>
        {/if}
    {/if}
</div>

<style>
    .card {
        @apply relative rounded-lg bg-black w-full flex flex-col overflow-hidden;
    }
    .card.col-span-2 .card-footer {
        @apply py-2;
    }
    .card-footer {
        @apply absolute bottom-0 w-full py-[0.1rem] px-2 text-left bg-black/30 text-sm flex justify-between font-impact;
    }

    .btnAR {
        @apply rounded-full bg-black/30 text-white text-xs px-4 py-[0.15rem] uppercase font-DINRoundPro-Bold;
    }

    .page-iframe {
        background-repeat: no-repeat;
        background-position: 52% 50%;
        background-image: url('/images/bg-iframe.webp');
        background-size: 39vw;
    }

    .close {
        text-shadow: #000 1px 0 8px;
    }

    .modal {
        @apply relative py-8 px-8 w-5/6 bg-neutral-900 flex flex-col justify-center items-center text-center rounded-lg;
    }

    .videoAstralProjection {
        @apply absolute top-0 left-0 w-full h-full bg-black z-[100] pointer-events-none;
        transition: opacity ease 2.5s;
    }
    .videoAstralProjection video {
        @apply w-full h-full object-fill;
    }
</style>
