import { readable, writable } from 'svelte/store';

// Current section active/visible on viewport
let defaultPage = location.hash ? location.hash.replace('#', '') : 'gallery';
if (defaultPage === 'wallet') {
    // avoid direct access to wallet
    defaultPage = 'gallery';
}
export const activePage = writable(defaultPage);

// Listener for hash changes
activePage.subscribe((newHash) => {
    if (location.hash !== `#${newHash}`) {
        location.hash = `#${newHash}`;
    }
    document.body.dataset.page = newHash;
});

// Page title shown on main app header
export const pageTitle = writable('');

// Check if the PWA is installed
export const isAppInstalled = writable(false);
export const isAlreadyInstalled = writable(false);

export const unrevealedImage = readable('/images/unrevealed-lulu.webp');

/**
 * @typedef ARK
 * @property {string} name
 * @property {string} image
 * @property {string} link
 */

/** @type {Array<ARK>} */
let arkList = [
    {
        name: 'ARK Level I',
        image: '/images/icon-ark.webp',
        link: 'https://lnk.to/RQANoELs'
    },
    {
        name: 'ARK Level II',
        image: '/images/icon-ark.webp',
        link: 'https://lnk.to/QmpELDQo'
    },
    {
        name: 'ARK Level III',
        image: '/images/icon-ark.webp',
        link: 'https://lnk.to/XQBMJ637'
    },
    {
        name: 'ARK Level IV',
        image: '/images/icon-ark.webp',
        link: 'https://lnk.to/JPsV80gm'
    },
    {
        name: 'ARK Level V',
        image: '/images/icon-ark.webp',
        link: 'https://lnk.to/iuPoJUN0'
    },
    {
        name: 'ARK Level VI',
        image: '/images/icon-ark.webp',
        link: 'https://lnk.to/9j2A9UfN'
    },
    {
        name: 'ARK Level VII',
        image: '/images/icon-ark.webp',
        link: 'https://lnk.to/98ojbFTa'
    }
];
export const arkExperiences = writable(arkList);
