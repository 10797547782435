<script>
    import { onMount } from 'svelte';
    import { pageTitle } from '../utils/Store';

    onMount(() => {
        pageTitle.set('WALLET');
    });
</script>

<div class="mx-auto w-[90%] rounded-3xl py-10 backdrop-blur-sm block font-DINRoundPro text-sm">
    <div class="mx-auto w-fit border border-gray-700/50 px-4 rounded-full">
        <div class="flex items-center py-2">
            <w3m-network-button />
            <w3m-button balance="show" />
        </div>
    </div>
</div>
