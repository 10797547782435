import { writable, get as getStore } from 'svelte/store';
import { showNetworkError } from './Errors';
import { getData, setData } from './LocalData';
import { get as getXHR } from './Backend';

/**
 * @typedef ChildToken
 * @property {number} [TokenID]
 * * @property {number} Epoch
 * @property {boolean} Revealed
 *
 * @typedef FamilyToken
 * @property {string} ParentName
 * @property {number} [TokenID]
 * @property {number} Epoch
 * @property {boolean} Revealed
 * @property {"Alchemist" | "Legendary"} Type
 * @property {ChildToken[]} Children
 */

/** @type {import('svelte/store').Writable<FamilyToken[]>} */
export const familyParents = writable([]);
async function loadFamilyTrees() {
    const endpoint = '/api/nfts/family/parents';
    try {
        const parents = await getXHR(endpoint, false);
        if (parents) {
            familyParents.set(parents);

            // Update our local indexedDB with fresh data
            setData(endpoint, parents);

            intervalUpdateData = 1 * 60 * 1000;
            setTimeout(loadFamilyTrees, intervalUpdateData);
        } else {
            intervalUpdateData = 30 * 1000;
            setTimeout(loadFamilyTrees, intervalUpdateData);
        }
    } catch (ex) {
        console.error('Family Tree Error:', ex.message);

        const parents = await getData(endpoint);
        // console.log('fallback to local DB?', parents);
        if (parents) {
            const currentFamilyData = getStore(familyParents);
            if (!currentFamilyData || currentFamilyData.length === 0) {
                // Update store only if we don't have data
                familyParents.set(parents);
            }

            intervalUpdateData = 60 * 1000; // try again in 1 minute
            setTimeout(loadFamilyTrees, intervalUpdateData);
        } else {
            if (ex.message && ex.message.includes('Network Error')) {
                showNetworkError();
            } else {
                // showErrorMessage({
                //     title: 'Server Error',
                //     message: 'Our servers are too busy! Please try again later'
                // });
            }

            intervalUpdateData = 10 * 1000; // Update interval to try 10 seconds
            setTimeout(loadFamilyTrees, intervalUpdateData);
        }
    }
}

let intervalUpdateData = 15 * 1000; // Default to 15 seconds n the first time
export function setupStoreFamily() {
    loadFamilyTrees();

    setTimeout(loadFamilyTrees, intervalUpdateData);
}
