<script>
    import { onMount } from "svelte";
    import { loadMediaBlob } from "../../utils/MediaUtils";

    export let onClose
    export let itemsList

    let itemsThumbnails = []
    let currentARK = null

    /** @type {HTMLElement} */
    let thumbnailsRef
    
    onMount(() => {
        if (Array.isArray(itemsList)) {
            itemsThumbnails = [...itemsList].slice(1)
            currentARK = itemsList[0]

            setTimeout(playAnimations, 100)
        }
    })

    async function playAnimations() {
        for (const item of itemsList) {

            /** @type {HTMLMediaElement} */
            const node = document.getElementById(item.name)
            if (node) {
                // console.log('Load node:', item.name, node)
                await loadMediaBlob(node, '/videos/pixelate-1.mp4')

            }
        }
    }

    function onBackClick() {
        onClose && onClose()
    }

    /**
     * 
     * @param {import("../../utils/Store").ARK} ark
     */
    function selectThumbnail(ark) {
        currentARK = ark
        if (ark.name!==itemsList[0].name) {
            itemsThumbnails = itemsList.filter(item => {
                return item.name !== ark.name
            })
        } else {
            itemsThumbnails = [...itemsList].slice(1)
        }
    }

    function openARK() {
        window.open(currentARK.link, '_blank')
    }
</script>
<div class="ark-gallery w-full h-full flex flex-col px-6">
    <header class="flex flex-none items-center w-full pt-4 pb-2 text-center z-10 relative">
        <button class="absolute z-50 top-3 left-0 text-2xl" on:click={onBackClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 inline">
                <path fill-rule="evenodd" d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd" />
            </svg><span class="inline text-transparent">. .</span>
        </button>
        
        <h1 class="font-impact text-white text-2xl mx-auto uppercase">ARK Experiences</h1>
    </header>


    {#if currentARK}
    <button class="w-full mb-8 flex flex-col items-center justify-center relative" on:click={openARK}>
        <video playsinline autoplay muted loop class="flex-none max-h-auto w-[90%] aspect-square z-10 mb-0" poster={currentARK.image}>
            <source src="/videos/pixelate-1.mp4" type="video/mp4" />
        </video>
        <span class="font-impact text-xl">{currentARK.name}</span>
    </button>
    {/if}

    <div class="w-full overflow-scroll flex-1">
        <div class="grid grid-cols-3 gap-2" bind:this={thumbnailsRef}>
            {#each itemsThumbnails as arkThumbnail}
                <button class="card" on:click={() => { selectThumbnail(arkThumbnail) }}>
                    <video id={arkThumbnail.name} playsinline autoplay muted loop class="w-full mt-2 aspect-square" poster={arkThumbnail.image}>
                        <!-- <source src="/videos/pixelate-1.mp4" type="video/mp4" /> -->
                    </video>
                    <span>{arkThumbnail.name}</span>
                </button>
            {/each}
        </div>
    </div>
</div>


<style>
    .card {
        @apply relative rounded-lg bg-black w-full flex flex-col overflow-hidden;
    }
    .card span {
        @apply relative text-sm px-1 pt-1 pb-2 bg-black/90 w-full
    }
</style>