// import { get as getXHR } from './Backend';
import { get, set, clear } from 'idb-keyval';

export function setData(key, data) {
    return set(key, data);
}

export async function getData(url) {
    const fromDB = await get(url);
    if (fromDB) {
        return fromDB;
    }

    // Then we need to read data from network:
    // const fromXHR = await getXHR(url, false);
    // setData(url, fromXHR);
    // return fromXHR;
}

export function deleteData() {
    return clear();
}
