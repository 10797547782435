<script>
    import { onMount, afterUpdate } from "svelte";

    import { pageTitle } from "../utils/Store";
    import { familyParents } from "../utils/Store-Family";
    import { getThumbnailEpoch } from "../utils/MediaUtils";
    import GalleryFamily from "./components/GalleryFamily.svelte";
    import { lazyAll } from "../utils/Lazy";
    import { swipeBack } from "../utils/SwippableBack";

    /** @type {import("../utils/Store").FamilyToken} */
    let selectedParent = null

    let galleryRef

    onMount(() => {
        pageTitle.set('FAMILY TREES')
    })

    afterUpdate(() => {
        // useFul when reload the page on this same section
        if ($familyParents.length>0 && galleryRef) {
            lazyAll(galleryRef)
        }
    })

    /**
     * 
     * @param {import("../utils/Store").FamilyToken} parent
     */
    async function openParent(parent) {
        // isAnimating = true
        selectedParent = parent
    }

    function closeParent() {
        selectedParent = null
    }
</script>
<div class="page-family w-full h-full flex flex-col relative overflow-hidden touch-none">
    <header class="flex items-center w-full py-4 text-center z-10 relative animate-translate">
        <h1 class="font-impact text-white text-2xl mx-auto uppercase">{$pageTitle}</h1>
    </header>
    
    <div class="page overflow-scroll">
        <div class="grid grid-cols-3 gap-2 pt-4" bind:this={galleryRef} use:lazyAll>
            {#if $familyParents.length > 0}
                {#each $familyParents as parent}
                <div class="square w-full aspect-square">
                    <button class="relative flex justify-center items-center w-full h-full" on:click={() => { openParent(parent) }}>
                        <img data-src={getThumbnailEpoch(parent.Epoch.toString())} alt={parent.ParentName} class="w-full aspect-square" />
                        <div class="overlay absolute top-0 left-0 w-full h-full bg-black bg-opacity-50"></div>
                        <span class="font-rademos text-4xl absolute z-20">{parent.ParentName}</span>
                    </button>
                </div>
                {/each}
            {:else}
                {#each Array(15) as _, index (index)}
                    <div class="animate-pulse square w-full aspect-square"></div>
                {/each}
            {/if}
        </div>
    </div>

    <div class="absolute page animate-linear bg-black z-20 flex flex-col" use:swipeBack={{ onClose: closeParent }}
        class:page-current={!!selectedParent} class:translate-x-full={!selectedParent} class:translate-x-0={!!selectedParent}>
        {#if selectedParent}
        <GalleryFamily parent={selectedParent} onClose={closeParent} />
        {/if}
    </div>
</div>



<style>
    .page {
        @apply w-full h-full px-6
    }

    .page-current {
        visibility: visible;
    }

    .animate-linear {
        transition: transform linear 0.1s;
    }
</style>