import { get } from '../Backend';
import { showNetworkError } from '../Errors';
import { getData, setData } from '../LocalData';
import Wallet from './Wallet';

/**
 * Load all NFTs from a wallet
 *
 * @typedef NFT
 * @property {string} token_id
 * @property {string} token_uri
 * @property {string} token_hash
 * @property {string} token_address
 * @property {string} symbol
 * @property {string} owner_of
 * @property {string} minter_address
 * @property {string} last_metadata_sync
 * @property {string} last_token_uri_sync
 * @property {string} block_number
 * @property {string} block_number_minted
 * @property {string} amount
 * @property {any} metadata
 *
 * @returns {Promise<NFT[]>}
 */
async function getNFTs() {
    let address = Wallet.getUserAddress(); //
    if (!address) {
        console.error('Address not available yet');
        return [];
    }

    console.log('Loading tokens...');

    // TODO: Disable this on prod
    // We are using hard coded wallet from Julian only for local testing
    const chain = Wallet.getChain()?.toLowerCase();

    //  || location.hostname.includes('odlabs.io')
    if (chain === 'sepolia') {
        address = '0x03A7697A50705EF14d946736cd3F30B9aB91FA08';
    }

    const endpoint = `/api/nfts/${chain}/wallet/${address}`;

    try {
        /** @type {NFT[] | null} */
        const data = await get(endpoint);

        // Save data on local indexedDB with latest data
        setData(endpoint, data);

        return data || [];
    } catch (ex) {
        const nftData = await getData(endpoint);
        if (nftData) {
            return nftData;
        }

        console.error('NFTs Error:', ex.message);
        if (ex.message && ex.message.includes('Network Error')) {
            showNetworkError();
        } else {
            // showErrorMessage({
            //     title: 'Server Error',
            //     message: 'Our servers are too busy! Please try again later'
            // });
        }
    }
}

export { getNFTs };
