import { writable } from 'svelte/store';
import { getNFTs } from './Wallet/NFTs';
import Wallet from './Wallet/Wallet';

/** @type import('svelte/store').Writable<Array<import("./Wallet/NFTs").NFT>> */
export const userNFTs = writable([]);

// Flag when metamask wallet is connected
export const isWalletConnected = writable(false);
export const walletIconStyles = writable('');

isWalletConnected.subscribe(async (val) => {
    if (!val) {
        Wallet.setLocalData(null);
        userNFTs.set([]);
        return;
    }

    // loadWalletData();
});

export async function loadWalletData() {
    // Load NFTs Gallery
    const tmpNFTs = await getNFTs();
    // const nfts = [
    //     ...tmpNFTs,
    //     ...tmpNFTs.map((nft) => {
    //         const token = { ...nft };
    //         token.token_id = token.token_id + '-2';
    //         return token;
    //     })
    // ];
    if (Array.isArray(tmpNFTs)) {
        userNFTs.set(tmpNFTs);
    }
}
