<script>
    import { connectWallet } from '../utils/Wallet/Connect';
    
    export let onTap = null

    let label = 'C O N N E C T'

    function onClickConnect() {
        label = 'Connecting'
        onTap && onTap()
        
        // allow render the web3modal while we connect
        document.body.classList.add('wallet-visible')

        const modal = connectWallet()
        setTimeout(() => {
            if (!modal.getState().open) {
                label = 'C O N N E C T'
            }
        }, 3000)
    }
</script>

<!-- class:hidden={!visible} -->
<section
    id="bottom-wallet-connect"
    class="modal">
    <h2 class="font-DINRoundPro-Bold text-2xl my-2">Connect your Wallet</h2>
    <h3 class="font-DINRoundPro-Med text-lg mb-8">to unlock the gallery</h3>
    <button class="button w-full text-xl py-2 tracking-tight" on:click={onClickConnect}>
        {label}
    </button>
</section>

<style>
    .modal {
        @apply relative py-8 px-8 w-5/6 bg-neutral-900 flex flex-col justify-center items-center text-center rounded-lg;
    }
    .button {
        @apply rounded-2xl bg-white text-black uppercase font-DINRoundPro-Bold py-2 px-4;
    }
</style>