import { createWeb3Modal } from './Web3Modal';

let localWallet = '';
let localChain = '';

/**
 * @type {ReturnType<import('@web3modal/ethers5').createWeb3Modal>}
 */
let web3Modal;

let web3ModalSubscribed = false;
let lastConnectionState = false;
let openFirstTime = true;
let isModalOpening = false;
function getModalConnection(onChangeConnection) {
    if (isModalOpening) {
        return null;
    }

    if (!web3Modal) {
        web3Modal = createWeb3Modal();

        if (!web3ModalSubscribed) {
            web3Modal.subscribeState(async (state) => {
                web3ModalSubscribed = true;

                if (window.localStorage.getItem('user-key') && state.open && openFirstTime) {
                    web3Modal.close();
                    openFirstTime = false;
                }

                const isConnected = web3Modal.getIsConnected();
                isModalOpening = false;

                if (isConnected === lastConnectionState) {
                    // console.log('connection not changed...', lastConnectionState)
                    return;
                }
                lastConnectionState = isConnected;

                try {
                    const walletProvider = web3Modal.getWalletProvider();

                    if (!walletProvider) {
                        onChangeConnection(isConnected);
                        return;
                    }

                    // on Mobile we have walletProvider.accounts
                    // on Desktop we have walletProvider.selectedAddress
                    if (isConnected) {
                        let wallet = getUserAddress();
                        location.hostname === 'localhost' &&
                            console.log('Wallet connected:', wallet);
                    }
                    onChangeConnection(isConnected);
                } catch (ex) {
                    console.error('Wallet Error', ex);
                    onChangeConnection(isConnected);
                }
            });
        }
    }

    web3Modal.open();
    isModalOpening = true;
    return web3Modal;
}

/**
 * Returns the user address from the connected wallet
 * @returns {String | null}
 */
function getUserAddress() {
    try {
        if (localWallet) {
            return localWallet;
        } else if (web3Modal) {
            return web3Modal.getAddress();
        }
    } catch (ex) {
        console.error('User Wallet not available', ex.message);
    }

    return null;
}

/**
 * Return the Chain name of the current chain connected
 * @returns {string}
 */
function getChain() {
    if (localChain) {
        return localChain;
    }

    if (web3Modal) {
        /** @type Number */
        const id = web3Modal.getChainId();

        // TODO: Replace this map with something from web3Modal real source
        const chains = {
            1: 'Mainnet',
            5: 'Goerli',
            11155111: 'Sepolia'
        };

        return chains[id];
    }

    return null;
}

/**
 *
 * @param {import('./Connect').WalletData} localData
 */
function setLocalData(localData) {
    if (localData) {
        localWallet = localData.wallet;
        localChain = localData.chain;
    } else {
        // Clean local data
        localWallet = '';
        localChain = '';
    }
}

export default {
    getModalConnection,
    getUserAddress,
    getChain,
    setLocalData
};
