import axios from 'axios';
import TTLCache from '@isaacs/ttlcache';

const localCache = new TTLCache({
    max: 10000,
    ttl: 1000 * 30 // 30 seconds?
});

const currentLocal = `${window.location.protocol}//${window.location.hostname}:3201`;

export const API_URL = import.meta.env.VITE_TOKEN_API_URL || currentLocal;
const API_HEADERS = {
    'Content-Type': 'application/json'
};

/**
 *
 * @param {string} endpoint
 * @returns
 */
export async function get(endpoint, cacheEnabled = true) {
    if (localCache.has(endpoint) && cacheEnabled) {
        return localCache.get(endpoint);
    }

    let url = API_URL + endpoint;
    if (endpoint.startsWith('https')) {
        url = endpoint;
    }
    const res = await axios.get(url, {
        headers: API_HEADERS,
        timeout: 10000,
        signal: AbortSignal.timeout(10000)
    });

    if (res && res.status >= 200) {
        localCache.set(endpoint, res.data);
        return res.data;
    }

    return null;
}

export function getIframeURL() {
    let webarBaseURL = 'https://app.odlabs.io/lulu/?v=_NOW_&appMode=_MODE_&lulu='; // ---> default for production
    if (
        window.location.hostname === 'localhost' ||
        window.location.hostname.startsWith('192.168.')
    ) {
        webarBaseURL = `https://${location.hostname}:8443/index-local.html?appMode=_MODE_&lulu=`;
    } else if (window.location.hostname.includes('onrender.com')) {
        webarBaseURL = 'https://ar.odlabs.io/lulu-by-index/?v=_NOW_&appMode=_MODE_&lulu=';
    }

    return webarBaseURL;
}
