import { registerSW } from 'virtual:pwa-register';
import { isAlreadyInstalled, isAppInstalled } from './Store';
import { showErrorMessage } from './Errors';

// Update PWA each 30min
const intervalMS = 30 * 60 * 1000;

export function registerManualSW() {
    if ('serviceWorker' in navigator) {
        registerSW({
            immediate: true,
            onRegisteredSW(_, r) {
                r &&
                    setInterval(() => {
                        r.update();
                    }, intervalMS);

                r.update();
                setTimeout(setupPWA, 1000);
                console.log('App registered!');
            },
            onRegisterError(err) {
                console.error('PWA Registration error', err);
            }
        });
    } else {
        showErrorMessage({
            title: 'Layer 8 Error',
            message: "Your browser doesn't support this Application!"
        });
    }
}

function setupPWA() {
    // @type import('@khmyznikov/pwa-install').PWAInstallElement */
    /** @type {any} */
    const pwaInstall = document.getElementsByTagName('pwa-install')[0];

    // const isLocalhost = window.location.hostname === 'localhost';

    isAppInstalled.set(pwaInstall.isUnderStandaloneMode);

    // console.log('isUnderStandaloneMode?', pwaInstall.isUnderStandaloneMode);
    if (!pwaInstall.isUnderStandaloneMode) {
        // console.log('available?', pwaInstall.isInstallAvailable);
        if (pwaInstall.isInstallAvailable) {
            isAlreadyInstalled.set(false);
            pwaInstall.showDialog(true);
        } else {
            isAlreadyInstalled.set(true);
            // pwaInstall.hideDialog();
            pwaInstall.install();
        }
        console.log('App not installed yet!');
        return;
    }

    // When the app is installed load data when is opened!
    import('./Store-Family').then((module) => {
        module.setupStoreFamily();
    });

    import('./Wallet/Connect').then((module) => {
        module.checkWalletLocal();
    });

    // On Chrome for Aandroid devices:
    const onInstall = () => {
        setTimeout(() => {
            // reload page after install app to make sure users are ready to use the app
            window.location.reload();
        }, 500);
    };

    pwaInstall.addEventListener('pwa-install-success-event', onInstall);
    pwaInstall.addEventListener('pwa-install-fail-event', onInstall);
}
