<script>
    import { onMount } from "svelte";

    export let videoWebM
    export let videoMov
    export let link = ''

    let chipActive = false
    // let tapActive = false

    onMount(() => {
        let intervalChip = setInterval(() => {
            chipActive =!chipActive;
        }, 6000)

        // let intervalTap = setInterval(() => {
        //     tapActive =!tapActive;
        // }, 3000)

        return () => {
            clearInterval(intervalChip)
            // clearInterval(intervalTap)
        }
    })

    function onClick() {
        if (link && link.startsWith('https://')) {
            window.open(link, '_blank')
        }
    }
</script>

<button class="w-full h-full relative flex items-center justify-center has-pointer-event overflow-hidden" on:click={onClick}>
    <video playsinline autoplay muted loop class="flex-none max-h-auto max-w-[70%] z-10">
        <source src={videoMov} type='video/mp4; codecs="hvc1"' />
        <source src={videoWebM} type="video/webm" />
    </video>
    <div class="chip animate-translate" class:-translate-x-full={!chipActive} class:translate-x-0={chipActive}>
        IG Experience
    </div>
    <!-- <div class="tap" class:opacity-0={!tapActive} class:opacity-1={tapActive}>
        Tap to Engage
    </div> -->
</button>


<style>
    .chip {
        @apply bg-neutral-900 text-neutral-200 rounded-full px-6 py-2 absolute z-20 top-4 left-0 text-sm;
    }

    /* .tap {
        @apply text-neutral-200 absolute z-20 bottom-3 text-sm;
        transition: opacity ease 500ms;
    } */
</style>