import { UiHelperUtil } from '@web3modal/ui';
import { isWalletConnected, walletIconStyles } from '../Store-Wallet';
import { activePage } from '../Store';
import Wallet from './Wallet';
import { get } from 'svelte/store';
import { deleteData } from '../LocalData';

const LOCAL_STORAGE_KEY = 'user-key';

/**
 * @typedef WalletData
 * @property {String} wallet - User Address
 * @property {String} chain - Current chain: 'mainnet' | 'sepolia'
 **/

/**
 * Save wallet data  on local storage
 *
 * @param {WalletData} data
 */
function saveWalletLocal(data) {
    try {
        // We are going to double encode just to avoid users enter custom wallets easily
        let b64 = window.btoa(JSON.stringify(data)).replaceAll('=', '');
        b64 = window.btoa(b64.split('').reverse().join(''));
        localStorage.setItem(LOCAL_STORAGE_KEY, b64);
    } catch (ex) {
        // clear localStorage in case we are not able to encode and store data
        invalidLocalStorage();
    }
}

/**
 * Read and decode wallet data from local storage
 * @returns {WalletData}
 */
function readWalletLocal() {
    let b64 = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (b64) {
        try {
            // Now let's double decode data
            b64 = window.atob(b64);
            const walletStored = window.atob(b64.split('').reverse().join(''));
            const data = JSON.parse(walletStored);
            return data;
        } catch (ex) {
            invalidLocalStorage();
        }
    }
    return null;
}

const invalidLocalStorage = () => {
    console.log('Invalid wallet data!');
    localStorage.removeItem('user-wallet');
};

export function connectWallet() {
    const modal = Wallet.getModalConnection((isConnected) => {
        // console.log('Connect Change:', isConnected);
        if (isConnected) {
            saveWalletLocal({
                wallet: modal.getAddress(),
                chain: Wallet.getChain()
            });

            const userAddress = Wallet.getUserAddress();
            if (userAddress) {
                const styles = UiHelperUtil.generateAvatarColors(userAddress);
                walletIconStyles.set(styles);
            } else {
                const defaultIconStyles = `
                background-color: gray;
                animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
                `;
                walletIconStyles.set(defaultIconStyles);
            }
        } else {
            // localStorage.removeItem('user-wallet');
            localStorage.clear();
            deleteData();
        }

        // Set connection after wallet is configured
        isWalletConnected.set(isConnected);

        // In case we are on the wallet page and wants to reconnect, just go back to gallery
        if (get(activePage) === 'wallet') {
            activePage.set('gallery');
        }

        document.body.classList.remove('wallet-visible');
    });

    return modal;
}

export function checkWalletLocal() {
    const data = readWalletLocal();
    if (data) {
        if (!data || !data.wallet || !data.chain) {
            return invalidLocalStorage();
        }

        Wallet.setLocalData(data);

        // Auto Connect wallet again without user interaction
        connectWallet();

        // const styles = UiHelperUtil.generateAvatarColors(data.wallet);
        // walletIconStyles.set(styles);

        // isWalletConnected.set(true);
    }
}
