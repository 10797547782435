<script>
    import { slide } from 'svelte/transition';
    import { quintOut } from 'svelte/easing';
    import { appErrorTitle, appErrorMessage, showError, closeError } from "../utils/Errors";

    const currentLocal = `${window.location.protocol}//${window.location.hostname}:3201`;
</script>

{#if $showError}
<div
    transition:slide={{  delay: 250, duration: 500, easing: quintOut, axis: 'y' }}
    class="absolute top-0 left-0 bg-neutral-800 text-neutral-100 font-press-start w-full px-6 pb-8 pt-12 z-50">

    <a href="#" class="block absolute right-0 top-4 text-3xl px-1 text-transparent touch-auto " on:click={closeError}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 inline text-white">
            <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
        </svg>.
    </a>

    <h2 class="mb-2 text-lg">{$appErrorTitle}</h2>
    <p>{$appErrorMessage}</p>
    
    {#if window.location.hostname==='localhost' || window.location.hostname.startsWith('192.168.')}
        <a class="block pt-2" href={currentLocal}>Check here!</a>
    {/if}
</div>
{/if}