import { writable } from "svelte/store"

export const appErrorTitle = writable('')
export const appErrorMessage = writable('')
export const showError = writable(false)

function showNetworkError() {
    showErrorMessage({
        title: 'Network Error:',
        message: 'Please check your connection!'
    })
}

function showErrorMessage({title, message}) {
    appErrorTitle.set(title)
    appErrorMessage.set(message)
    showError.set(true)
}

function closeError() {
    showError.set(false)
}

export {
    showErrorMessage,
    showNetworkError,
    closeError
}