<script>
    import '@khmyznikov/pwa-install';
    import { onMount, afterUpdate } from 'svelte';
    import { activePage, isAppInstalled } from './utils/Store';
    import { preventBrowserHistorySwipeGestures } from './utils/BrowserSwipe';
    import { registerManualSW } from './utils/PWA';

    // UI Components:
    import BottomNav from './components/BottomNav.svelte';
    import ErrorMessage from './components/ErrorMessage.svelte';

    import GalleryPage from './pages/Gallery.svelte';
    import AboutPage from './pages/About.svelte';
    import WalletPage from './pages/Wallet.svelte';
    import FamilyPage from './pages/Family.svelte';
    import ExperiencesPage from './pages/Experiences.svelte';

    // Dynamic import only when it's needed
    let Desktop;

    // Flag to validate if the bg video can be played (due to low-battery mode on iOS)
    let videoActive = false;

    // Flag to toggle features based on screen size
    let appWidth = window.innerWidth;
    let appHeight = window.innerHeight;
    $: isMobile = navigator.maxTouchPoints > 1 || appWidth * 1.33 <= appHeight;

    onMount(() => {
        if (isMobile) {
            registerManualSW();

            let backGesture = null;
            if ('ontouchstart' in window) {
                backGesture = preventBrowserHistorySwipeGestures();
            }

            return () => {
                backGesture && backGesture();
            };
        } else {
            import('./components/Desktop.svelte').then((module) => {
                Desktop = module.default;
            });
        }
    });

    afterUpdate(() => {
        if (!isMobile && !Desktop) {
            import('./components/Desktop.svelte').then((module) => {
                Desktop = module.default;
            });
        }
    });

    function onPlayBGVideo() {
        videoActive = true;
    }
</script>

<svelte:window bind:innerWidth={appWidth} bind:innerHeight={appHeight} />

<main class="flex flex-col h-full touch-none">
    {#if isMobile}
        <video
            on:playing={onPlayBGVideo}
            autoplay
            playsinline
            muted
            loop
            class:opacity-0={!videoActive}
            class="bg-video absolute z-0 top-0 left-0 w-full h-full pointer-events-none object-cover"
            src="videos/bg.mp4"
        >
        </video>
    {/if}

    {#if isMobile}
        <div class="side-wrapper flex w-full h-full">
            <!-- bind:this={sideWrapperRef}-->
            <!-- <Sidebar walletIconStyles={$walletIconStyles} /> -->

            <div class="content-wrapper flex flex-col min-w-full w-full h-full">
                <section
                    id={$activePage}
                    class="animate-translate min-w-full flex-grow flex-1 w-full h-full relative z-1 bg-black bg-opacity-30 flex flex-col items-center overflow-hidden touch-none"
                >
                    {#if $activePage === 'weare'}
                        <svelte:component this={AboutPage} />
                    {/if}

                    {#if $activePage === 'gallery'}
                        <svelte:component this={GalleryPage} />
                    {/if}

                    {#if $activePage === 'wallet'}
                        <svelte:component this={WalletPage} />
                    {/if}

                    {#if $activePage === 'tryme'}
                        <svelte:component this={ExperiencesPage} />
                    {/if}

                    {#if $activePage === 'family'}
                        <svelte:component this={FamilyPage} />
                    {/if}
                </section>

                {#if $isAppInstalled}
                    <svelte:component this={BottomNav} />
                {/if}
            </div>
        </div>

        <pwa-install disable-close manifest-url="/manifest.webmanifest"> </pwa-install>

        <div class="landscape z-10 w-full h-full items-center justify-center">
            <section
                class="py-4 px-4 w-3/5 bg-neutral-800/70 backdrop-blur-sm flex flex-row justify-center items-center rounded-lg"
            >
                <img src="/images/rotate-alpha.webp" alt="rotate" class="w-16 mr-4" />
                <h3 class="font-DINRoundPro-Med text-sm text-left">
                    Please rotate your device into portrait mode
                </h3>
            </section>
        </div>
    {:else}
        <svelte:component this={Desktop} />
    {/if}

    <svelte:component this={ErrorMessage} />
</main>

<style>
    @media (orientation: landscape) {
        .side-wrapper,
        .bg-video {
            display: none;
        }
        .landscape {
            display: flex;
        }
    }

    @media (orientation: portrait) {
        .landscape {
            display: none;
        }
    }
</style>
