<script>
    import { onMount } from "svelte";
    import { pageTitle } from "../utils/Store";

    onMount(() => {
        pageTitle.set('WHO WE ARE')
    })
</script>
<div class="h-full flex flex-col px-6 font-DINRoundPro touch-none">
    <header class="flex items-center w-full py-4 text-center z-10 relative animate-translate">
        <h1 class="font-impact text-white text-2xl mx-auto uppercase">{$pageTitle}</h1>
    </header>

    <div class="page-weare flex flex-col w-full h-full justify-center">
        <img src="/images/odlabs.svg" alt="ODLabs" class="w-3/5 mx-auto mb-6" />
    
        <p class="desc">
            A COMPANY BY INNOVATIVE ARTISTS, DESIGNERS, AND SOFTWARE DEVELOPERS WORKING TOGETHER TO BRIDGE PHYSICAL AND DIGITAL WORLDS.
        </p>
        <p class="desc">
            THE AR/XR TECH DIVISION OF CANADA'S LARGEST STREETWEAR CONSIGNMENT BRAND <span>ODTO</span>, AND FASHION/LIFESTYLE BRAND <span>OD</span>
        </p>
        
        <div class="font-DINRoundPro-Bold mt-3 mb-4">TO LEARN MORE VISIT</div>
    
        <div class="links uppercase font-DINRoundPro-Bold mb-4 flex flex-col gap-2">
            <a href="https://odlabs.io/?ref=app" target="_blank">odlabs.io</a>
            <a href="https://odto.com/?ref=app" target="_blank">odto.com</a>
        </div>
    
        <div class="links flex font-DINRoundPro-Bold text-sm">
            <a href="https://www.instagram.com/ODLabs.io" target="_blank" class="flex w-1/2">
                <img src="./images/instagram.svg" alt="Instagram" class="w-5 mr-2">
                @ODLABS.IO
            </a>
            <a href="https://www.x.com/ODLabs" target="_blank" class="flex w-1/2">
                <img src="./images/twitter.svg" alt="X (former Twitter)" class="w-5 mr-2">
                @ODLABS
            </a>
        </div>
        <div class="links flex font-DINRoundPro-Bold text-sm mt-3">
            <a href="https://www.instagram.com/odtoronto" target="_blank" class="flex w-1/2">
                <img src="./images/instagram.svg" alt="Instagram" class="w-5 mr-2">
                @ODTORONTO
            </a>
        </div>

    </div>
    
</div>

<style>
    .desc {
        @apply my-1 text-lg;
    }
    .desc span {
        @apply text-violet-600 font-DINRoundPro-Bold;
    }
</style>