import './app.css';
import App from './App.svelte';
import * as Sentry from '@sentry/svelte';

if (window.location.hostname === 'app.odlabs.io') {
    Sentry.init({
        dsn: 'https://750dee57806ee2823e222dbf65f61ba0@o4507006304780288.ingest.us.sentry.io/4507006310612992',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false
            })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/server\.odlabs\.io\/api/],
        
        // Session Replay
        // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        // replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const app = new App({
    target: document.getElementById('app')
});

export default app;
