<script>
    import Carousel from 'svelte-carousel';
    import { onMount } from 'svelte';
    import { pageTitle, arkExperiences } from '../utils/Store';
    import { swipeBack } from '../utils/SwippableBack';
    import SlideIG from './components/SlideIG.svelte';
    import GalleryARK from './components/GalleryARK.svelte';

    // Flag to show ARK Gallery
    let showARK = false;
    let isWebM = false;

    onMount(() => {
        pageTitle.set('Experiences');
    });

    function toggleARKGallery() {
        showARK = !showARK;
    }
</script>

<div class="w-full h-full relative flex flex-col">
    <header
        class="flex flex-none items-center w-full py-4 text-center z-10 relative animate-translate"
    >
        <h1 class="font-impact text-white text-2xl mx-auto uppercase">{$pageTitle}</h1>
    </header>

    <div class="experiences w-full h-full flex flex-col justify-center">
        <Carousel arrows={false}>
            <button
                class="w-full h-full flex items-center justify-center has-pointer-event"
                on:click={toggleARKGallery}
            >
                <video
                    autoplay
                    playsinline
                    muted
                    loop
                    id="slide-ark-video"
                    class="flex-none max-h-auto max-w-[70%] aspect-square z-10"
                >
                    <source src="videos/pixelate-1.mov" type="video/mp4; codecs=&quot;hvc1&quot" />
                    <source src="videos/pixelate-2.webm" type="video/webm" />
                </video>
            </button>

            <SlideIG
                videoMov="videos/can-1.mov"
                videoWebM="videos/can-1.webm"
                link="https://www.instagram.com/ar/396960093070443/"
            />

            <!-- <SlideIG videoMov="videos/car-1.mov" videoWebM="videos/car-1.webm" link="https://www.instagram.com/ar/7520173704708456/" /> -->
        </Carousel>
    </div>

    <div
        class="absolute top-0 right-0 w-full h-full animate-linear bg-black z-20 flex flex-col"
        use:swipeBack={{ onClose: toggleARKGallery }}
        class:page-current={showARK}
        class:translate-x-full={!showARK}
        class:translate-x-0={showARK}
    >
        {#if showARK}
            <GalleryARK itemsList={$arkExperiences} onClose={toggleARKGallery} />
        {/if}
    </div>
</div>

<style>
    .experiences {
        --sc-dot-size: 10px;
        --sc-active-dot-size: 12px;
        --sc-color-rgb-light: #dbd9d9;
    }
    .page-current {
        visibility: visible;
    }

    .animate-linear {
        transition: transform linear 0.1s;
    }

    :global(.experiences .sc-carousel-dots__container) {
        gap: 6px;
        margin-top: 2rem;
    }
    :global(.experiences button video) {
        @apply scale-110;
    }
</style>
