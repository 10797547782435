import { loadMediaBlob } from './MediaUtils';

export function lazy(node, params) {
    function observation(entries) {
        if (!entries[0].isIntersecting) return;

        if (!node.src) {
            loadMediaBlob(node, node.dataset.src);
        }
        observer.unobserve(node);
    }

    const observer = new IntersectionObserver(observation, params);

    observer.observe(node);

    return {
        onDestroy() {
            observer.unobserve(node);
        }
    };
}

export function lazyAll(node, params) {
    function observation(entries) {
        entries.forEach((entry) => {
            if (!entry.isIntersecting) return;
            const { target } = entry;
            if (!target.src) {
                loadMediaBlob(target, target.dataset.src);
            }
            observer.unobserve(target);
        });
    }

    const observer = new IntersectionObserver(observation, params);

    const imgs = node.querySelectorAll('img');
    imgs.forEach((img) => {
        if (img.dataset.src) {
            observer.observe(img);
        }
    });

    return {
        onDestroy() {
            imgs.forEach((img) => observer.unobserve(img));
        }
    };
}
